/*保险接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

export function GetInsurSearch(parm, callback) {
	console.log('GetInsurSearch');
	postAjaxRequest('/INSApi/GetInsurSearch', parm, function(result) {
		callback(result);
	})	
}
//获取热门保险
export function GetHotInsList(parm, callback) {
	console.log('GetHotInsList');
	postAjaxRequest('/INSApi/GetHotInsList', parm, function(result) {
		callback(result);
	})	
}
//保险公司列表
export function GetInsurOwners(parm, callback) {
	console.log('GetInsurOwners');
	postAjaxRequest('/INSApi/GetInsurOwners', parm, function(result) {
		callback(result);
	})	
}
//保险列表
export function GetInsuranceRateList(parm, callback) {
	console.log('GetInsuranceRateList');
	postAjaxRequest('/INSApi/GetInsuranceRateList', parm, function(result) {
		callback(result);
	})	
}
//保险详情
export function GetInsuranceDetail(parm, callback) {
	console.log('GetInsuranceDetail');
	postAjaxRequest('/INSApi/GetInsuranceDetail', parm, function(result) {
		callback(result);
	})	
}
//预定保险
export function BookerInsurance(parm, callback) {
	console.log('BookerInsurance');
	postAjaxRequest('/INSApi/BookerInsurance', parm, function(result) {
		callback(result);
	})	
}
//获取预定的保险信息
export function GetBookerInsuranceInfo(parm, callback) {
	console.log('GetBookerInsuranceInfo');
	postAjaxRequest('/INSApi/GetBookerInsuranceInfo', parm, function(result) {
		callback(result);
	})	
}
//投保人关系列表
export function GetInsuranceRelationships(parm, callback) {
	console.log('GetInsuranceRelationships');
	postAjaxRequest('/INSApi/GetInsuranceRelationships', parm, function(result) {
		callback(result);
	})	
}
