<template>
	<div class="confirm-a">
		<!-- <ul class="confirm-b confirm-c">
			<li :class="(item.current == true ? 'current ' : '') + 'confirm-d  confirm-c'" v-for="(item, index) in progress"
			 :key="index">
				<span class="confirm-e"></span>
				<a class="confirm-f">{{ item.name }}</a>
			</li>
		</ul> -->
		<div class="confirma-ba">
			<div class="confirma-jz">
				<!-- 详情最新头部改动  头部步骤条 -->
				<div class="confirma-jza">
					<div class="iconfont icon-baoxian2 confirma-jzc"></div>
					<div class="confirma-jzd">选择保险 Select</div>
				</div>
				<div class="confirma-jzb"></div>
				<div class="confirma-jza">
					<div class="iconfont icon-bianji2 confirma-jzc confirma-jzcc"></div>
					<div class="confirma-jzd">填写资料 Fill in</div>
				</div>
				<!-- <div class="confirma-jzb"></div>
				<div class="confirma-jza">
					<div class="iconfont icon-duigou confirma-jze"></div>
					<div class="confirma-jzf">确认资料 Confirm</div>
				</div> -->
				<div class="confirma-jzb"></div>
				<div class="confirma-jza">
					<div class="iconfont icon-xiangshang11 confirma-jze confirma-jzee"></div>
					<div class="confirma-jzf">提交订单 Submit</div>
				</div>
			</div>
		</div>


		<div class="confirma-a">
			<el-form ref="form" :model="form" class="confirma">
				<div class="confirm-aab">
					<div class="confirm-aaa">
						<span class="confirm-ae">其他旅客</span>
						<div class="confirm-af">
							<div class="confirm-ak confirm-aka" @click="bindShowOftenPassenger">
								<strong class="jiahao iconfont icon-xuanze"></strong>
								选择旅客
							</div>
							<div class="confirm-ak confirm-akb" @click="addPassenger">
								<strong class="jiahao iconfont icon-jiahao"></strong>
								添加1个旅客
							</div>
						</div>
						<!-- <span>
							需要添加的旅客
							<span>成人：1人</span>
							&nbsp;
							<span>老人：1人</span>
							&nbsp;
							<span>儿童：1人</span>
						</span> -->
					</div>

					<div class="confirm-xm">
						<!-- <table class="table confirm-xmm pessenger-list pessenger-list-head ">
							<tbody>
								<tr>
									<td class="p-td01">姓名</td>
									<td class="p-td02">性别</td>
									<td class="p-td12">国籍</td>
									<td class="p-td12">出生日期</td>
									<td class="p-td03">证件类型</td>
									<td class="p-td04">证件号</td>
									<td class="p-td05">证件有效期</td>
									<td class="p-td08">国家区号-手机号码</td>
									<td class="p-td10"></td>
								</tr>
							</tbody>
						</table> -->

						<div>
							<!-- <span class="add-person-btn" @click="addPassenger">
							<strong>+</strong>
							添加一个常用旅客
						</span> -->
						</div>

						<div class="confirm-tj">
							<!-- 添加一个常用客 -->
							<div class="confirm-tjh" v-for="(item, index) in form.PassengerListData" :key="index">
								<div class="confirm-dii">
									<div class="confirm-di">
										<div class="confirm-diii">
											<span class="confirm-diw">
												<div class="confirm-diww iconfont icon-chengke"></div>
												<div class="confirm-p">旅客{{ index + 1 }}</div>
											</span>

											<!-- <el-checkbox v-model="checked1" class="confirm-ta iconfont icon-jizhumima-yixuanze"><p class="confirm-ptbr">投保人</p></el-checkbox>
														
											<el-checkbox v-model="checked2" class="iconfont icon-jizhumima-yixuanze"><p class="confirm-ptbr">被保人</p></el-checkbox> -->
											<el-row class="confirm-ytb" type="flex" align="middle">
												<span class="confirm-tbrgx">投保角色</span>
												<el-select v-model="item.InsuranceRole" placeholder="--请选择--"
													size="small" popper-class="confirm-icon">
													<el-option v-for="item in InsuranceRoles" :key="item.ID"
														:label="item.Name" :value="item.ID"></el-option>
												</el-select>
												<span class="confirm-icona iconfont icon-xiangxia1"></span>
											</el-row>
											<el-row class="confirm-ytb" type="flex" align="middle"
												v-show="item.InsuranceRole == 3">
												<span class="confirm-tbrgx">与投保人关系</span>
												<el-select v-model="item.InsureRelation" placeholder="--请选择--"
													size="small" popper-class="confirm-icon">
													<el-option v-for="item in InsuranceRelationships" :key="item.Code"
														:label="item.Name" :value="item.Code"></el-option>
												</el-select>
												<span class="confirm-icona iconfont icon-xiangxia1"></span>
											</el-row>
										</div>
										<a href="javascript:;" class="confirm-sc" @click="delPassenger(index)">删除</a>
									</div>
								</div>

								<div class="confirm-topab">
									<div class="confirm-topa">
										<div class="confirm-top confirm-topr">
											<!-- <span class="confirm-text confirm-texta">姓名</span> -->
											<span class="confirm-xingm" v-show="ximing">
												<!-- 姓名 -->
												<el-input type="text" style="width: 100%;" class="confirm-inputa"
													v-model="item.Man_xingming" placeholder="请输入旅客姓名"></el-input>
												<!-- <el-input type="text" style="width: 156px;" class="confirm-inputb" v-model="item.Man_ming" placeholder="名(first name)"></el-input> -->
											</span>

											<!-- 证件选择身份证时显示这个 -->
											<!-- <span class="confirm-sfz" v-show="sfz">
												<el-input type="text" class="confirm-inputds" v-model="input7" placeholder="姓名"></el-input>
											</span> -->
										</div>
										<div class="confirm-top confirm-topri">
											<div class="confirm-topb">
												<!-- <span class="confirm-text cnfirm-textd">性别</span> -->
												<!-- <el-select v-model="value2" class="confirme-nv" placeholder="男" size="small" popper-class="confirm-iconn">
														<el-option v-for="item in nannv" :key="item.value2" :label="item.label2" :value="item.value2"></el-option>
													</el-select> -->

												<el-select v-model="item.Man_sex" placeholder="性别" size="small"
													popper-class="confirm-iconn" class="confirme-nv">
													<el-option label="男" value="1"></el-option>
													<el-option label="女" value="0"></el-option>
												</el-select>
												<span class="confirm-iconc iconfont icon-xiangxia1"></span>
											</div>
										</div>

										<div class="confirm-top">
											<!-- <span class="confirm-text confirm-textc">出生日期</span> -->
											<div class="confirm-topb">
												<el-date-picker v-model="item.Birthday" type="date"
													value-format="yyyy-MM-dd" placeholder="出生日期" :clearable="false"
													prefix-icon="none" style="width: 100%;"></el-date-picker>
												<!-- <el-date-picker
													class="confirm-inpute"
													:clearable="false"
													v-model="value1"
													prefix-icon="none"
													type="date"
													placeholder="必填"
												></el-date-picker> -->
												<span class="confirm-icond iconfont el-icon-date
	"></span>
											</div>
										</div>
									</div>

									<div class="confirm-topa confirm-top">
										<div class="confirm-topb">
											<el-select v-model="item.CertificateType" placeholder="-- 请选择 --"
												class="confirm-topp" popper-class="confirm-iconl">
												<el-option v-for="CertificateTypeItem in item.CertificateTypeList"
													:key="CertificateTypeItem.value" :label="CertificateTypeItem.name"
													:value="CertificateTypeItem.value"></el-option>
											</el-select>
											<span class="confirm-iconb iconfont icon-xiangxia1"></span>
										</div>
										<div class="confirm-topc"></div>
										<div>
											<el-input type="text" style="width: 100%;" :clearable="false"
												class="confirm-inputc" v-model="item.CertificateNo"
												placeholder="请输入证件号码">
											</el-input>
										</div>
										<div class="confirm-topc"></div>
										<div class="confirm-topb">
											<!-- <span class="confirm-text confirm-textb">证件有效期</span> -->
											<el-date-picker class="confirm-inputd" :clearable="false"
												v-model="item.CertificateDate" prefix-icon="none" type="date"
												placeholder="证件有效期"></el-date-picker>
											<span class="confirm-icond iconfont el-icon-date"></span>
										</div>
									</div>

									<div class="confirm-topa confirm-top">
										<div class="confirm-topb">
											<!-- <span class="confirm-text confirm-texte">国籍</span> -->
											<el-cascader class="confirm-cn" popper-class="confirm-icong"
												v-model="item.Nationality" :options="Countrys" :show-all-levels="false"
												filterable></el-cascader>
											<span class="confirm-iconb iconfont icon-xiangxia1"></span>
										</div>
										<div class="confirm-topc"></div>
										<div class="confirm-topb">
											<el-select class="confirm-dh" v-model="value" placeholder="中国大陆+86">
												<el-option v-for="item in optionst" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
											<span class="confirm-iconb iconfont icon-xiangxia1"></span>
										</div>
										<div class="confirm-topc"></div>
										<div class="">
											<el-input type="text" style="width: 120px;" class="confirm-sjbt"
												v-model="item.Phone" placeholder="请输入手机号码"></el-input>
										</div>
										<!-- <div class=""> -->
										<!-- 原先填电话号码组件 -->
										<!-- <span class="confirm-text confirm-textf">手机号码</span> -->
										<!-- <span>
													<el-input type="text" style="width: 60px;" class="confirm-sj"
														v-model="item.Area" placeholder="中国大陆+86"></el-input>
													<span style="margin-right: 2px; margin-left: 2px;">-</span>
													<el-input type="text" style="width: 120px;" class="confirm-sjbt" v-model="item.Phone" placeholder="请输入手机号码"></el-input>
												</span> -->
										<!-- </div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
					<personNoData msg="没有数据" v-show="false"></personNoData>
				</div>


				<div class="confirm-yha" v-show="false">

					<div class="confirm-xjxj confirm-xxj" :class="{'confirm-xjj':!show,'confirm-xjjj':show}">
						<div class="confirm-xja">
							<div class="">现金卷</div>
							<div class="confirm-xjb" @click="show=!show">
								<div class="">未使用</div>
								<div class="confirm-xjif iconfont icon-jiantouxiamian"
									:class="{ 'confirm-xia': !show, 'confirm-shang': show}"></div>
							</div>
						</div>

						<div class="confirm-xjd">
							<div class="confirm-xjdd">
								<div class="confirm-xjy">
									<img src="../../assets/xianjin.png" class="confirm-xjimg">
									<div class="confirm-xjyy">
										<div class="">¥</div>
										<div class="confirm-xjyyy">1000</div>
									</div>
								</div>
								<div class="confirm-xjxx">
									<div class="confirm-xjxxx">
										<div class="confirm-xjx">
											<div class="confirm-xjxa">现金卷</div>
											<div class="confirm-xjxb">国庆中秋国内游</div>
										</div>
										<div class="confirm-xjx">
											<div class="">卷号：</div>
											<div class="">693183171</div>
										</div>
										<div class="confirm-xjx">
											<div class="">有效期：</div>
											<div class="">2020.10.23至2020.11.30</div>
										</div>
										<div class="confirm-xjs" @click="show5=!show5">使用规则</div>
									</div>
									<div class="booking-merchanta" @click="show1=!show1">
										<div class="iconfont icon-gouxuan3 book-tick" v-show="show1==1"></div>
										<div class="iconfont icon-gouxuan3 book-ticka" v-show="show1==0"></div>
									</div>
								</div>
							</div>

							<div class="confirm-xjdd">
								<div class="confirm-xjy">
									<img src="../../assets/xianjin.png" class="confirm-xjimg">
									<div class="confirm-xjyy">
										<div class="">¥</div>
										<div class="confirm-xjyyy">500</div>
									</div>
								</div>
								<div class="confirm-xjxx">
									<div class="confirm-xjxxx">
										<div class="confirm-xjx">
											<div class="confirm-xjxa">现金卷</div>
											<div class="confirm-xjxb">国庆中秋国内游</div>
										</div>
										<div class="confirm-xjx">
											<div class="">卷号：</div>
											<div class="">693183171</div>
										</div>
										<div class="confirm-xjx">
											<div class="">有效期：</div>
											<div class="">2020.10.23至2020.11.30</div>
										</div>
										<div class="confirm-xjs" @click="show5=!show5">使用规则</div>
									</div>
									<div class="booking-merchanta" @click="show2=!show2">
										<div class="iconfont icon-gouxuan3 book-tick" v-show="show2==1"></div>
										<div class="iconfont icon-gouxuan3 book-ticka" v-show="show2==0"></div>
									</div>
								</div>
							</div>

							<!-- <div class="confirm-xjwsy">
								<div class="confirm-xjb" @click="show3=!show3">
									<div class="">更多现金卷</div>
									<div class="confirm-xjif iconfont icon-jiantouxiamian"
										:class="{ 'confirm-xia': !show3, 'confirm-shang': show3}"></div>
								</div>
							</div> -->
						</div>
					</div>

					<div class="confirm-xjxj confirm-xxxj" :class="{'confirm-xjj':!show4,'confirm-xjjj':show4}">
						<!-- 优惠卷 -->
						<div class="confirm-xja">
							<div class="">优惠卷</div>
							<div class="confirm-xjb" @click="show4=!show4">
								<div class="">未使用</div>
								<div class="confirm-xjif iconfont icon-jiantouxiamian"
									:class="{ 'confirm-xia': !show4, 'confirm-shang': show4}"></div>
							</div>
						</div>

						<div class="confirm-xjd">
							<div class="confirm-xjdd">
								<div class="confirm-xjy">
									<img src="../../assets/xianjin.png" class="confirm-xjimg">
									<div class="confirm-xjyy">
										<div class="">¥</div>
										<div class="confirm-xjyyy">1000</div>
									</div>
								</div>
								<div class="confirm-xjxx">
									<div class="confirm-xjxxx">
										<div class="confirm-xjx">
											<div class="confirm-xjxa">现金卷</div>
											<div class="confirm-xjxb">国庆中秋国内游</div>
										</div>
										<div class="confirm-xjx">
											<div class="">卷号：</div>
											<div class="">693183171</div>
										</div>
										<div class="confirm-xjx">
											<div class="">有效期：</div>
											<div class="">2020.10.23至2020.11.30</div>
										</div>
										<div class="confirm-xjs" @click="show5=!show5">使用规则</div>
									</div>
									<div class="booking-merchanta" @click="show6=!show6">
										<div class="iconfont icon-gouxuan3 book-tick" v-show="show6==1"></div>
										<div class="iconfont icon-gouxuan3 book-ticka" v-show="show6==0"></div>
									</div>
								</div>
							</div>

							<div class="confirm-xjdd">
								<div class="confirm-xjy">
									<img src="../../assets/xianjin.png" class="confirm-xjimg">
									<div class="confirm-xjyy">
										<div class="">¥</div>
										<div class="confirm-xjyyy">500</div>
									</div>
								</div>
								<div class="confirm-xjxx">
									<div class="confirm-xjxxx">
										<div class="confirm-xjx">
											<div class="confirm-xjxa">现金卷</div>
											<div class="confirm-xjxb">国庆中秋国内游</div>
										</div>
										<div class="confirm-xjx">
											<div class="">卷号：</div>
											<div class="">693183171</div>
										</div>
										<div class="confirm-xjx">
											<div class="">有效期：</div>
											<div class="">2020.10.23至2020.11.30</div>
										</div>
										<div class="confirm-xjs" @click="show5=!show5">使用规则</div>
									</div>
									<div class="booking-merchanta" @click="show7=!show7">
										<div class="iconfont icon-gouxuan3 book-tick" v-show="show7==1"></div>
										<div class="iconfont icon-gouxuan3 book-ticka" v-show="show7==0"></div>
									</div>
								</div>
							</div>

							<!-- <div class="confirm-xjwsy">
								<div class="confirm-xjb" @click="show3=!show3">
									<div class="">更多现金卷</div>
									<div class="confirm-xjif iconfont icon-jiantouxiamian"
										:class="{ 'confirm-xia': !show3, 'confirm-shang': show3}"></div>
								</div>
							</div> -->
						</div>

						<!-- 优惠卷 -->
					</div>

					<div class="" :class="{ 'confirm-xjsza': !show5, 'confirm-xjsz': show5}">
						<div class="" :class="{ 'confirm-xjsb': !show5, 'confirm-xjsa': show5}">
							<div class="confirm-xjsy">
								<div class="">使用规则：</div>
								<div class="confirm-xjsya iconfont icon-guanbi2" @click="show5=false"></div>
							</div>
							<div class="confirm-xjsyb">
								<div class="confirm-sygz" v-for="item in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]"
									:key="item">
									{{item}}、（请准确填写联系人信息，重要信息我们会通过手机短信或邮件方式通知您）
								</div>
							</div>
						</div>
					</div>

				</div>

				<div class="confirm-lxr">
					<div class="confirm-lxr-a">
						<span class="">联系人信息</span>
						<i class="confirm-lxr-x">（请准确填写联系人信息，重要信息我们会通过手机短信或邮件方式通知您）</i>
					</div>
					<div class="confirm-lxr-b">
						<div class="confirm-zw">
							<div class="">
								<!-- <i class="confirm-xh">*</i> -->
								姓名
							</div>
							<el-input v-model="form.linkman" class="confirm-lcq" size="small" placeholder="">
							</el-input>
						</div>

						<div class="confirm-zw">
							<div>
								<!-- <i class="confirm-xh">*</i> -->
								联系号码
							</div>
							<el-input size="small" v-model="form.phone" placeholder=""></el-input>
							<!-- <el-row type="flex" align="middle">
								<el-col :span="14">
									<el-input size="small" v-model="form.area"></el-input>
								</el-col>
								<span style="margin-left: 2px; margin-right: 2px;">-</span>
								<el-input size="small" v-model="form.phone" placeholder="请填写手机号码"></el-input>
							</el-row> -->
						</div>

						<div class="confirm-zw">
							<div>
								<!-- <i class="confirm-xh"></i> -->
								电子邮箱
							</div>
							<el-col :span="12">
								<el-input v-model="form.email" size="small" placeholder=""></el-input>
							</el-col>
						</div>
						<!-- <el-row type="flex" class="confirm-zw" align="middle">
							<div>
								<span>
									<i class="confirm-xh"></i>
									联系邮箱：
								</span>
							</div>
							<el-col :span="12">
								<el-input v-model="form.email" size="small" placeholder="请填写邮箱"></el-input>
							</el-col>
						</el-row> -->
					</div>
				</div>

				<div class="confirm-fj">
					<div class="confirm-lxr-a">
						附加信息
						<!-- <i class="ft12 gray9">（选填）</i> -->
					</div>
					<div class="confirm-lxr-c">
						<el-input type="textarea" v-model="form.remark" resize="none" :rows="6"></el-input>
					</div>
				</div>



				<div class="confirm-fha">
					<!-- 返回  下一步 -->

					<!-- <a href="javascript:;" @click="fanhui" class="confirm-fh">返回</a> -->
					<a href="javascript:;" @click="bindSubmit" class="confirm-xyb">
						<div class="confirm-xyb">提交订单</div>
						<!-- <el-button type="danger" class="confirm-xyb">下一步</el-button> -->
					</a>
				</div>
			</el-form>


			<div class="confirm-aa">
				<!-- <div class="confirm-ab">
					保险信息
					<a href="javascript:;" @click="insu" class="confirm-ac">
						<span class="confirm-ad confirm-ae iconfont icon-fanhui"></span>
						<i class="confirm-ae">重新选择保险</i>
					</a>
				</div> -->

				<block v-if="BookInsuranceInfo != ''">
					<block v-for="(item, index) in BookInsuranceInfo" :key="index">
						<div class="confirm-ab">
							<div class="confirm-ba">
								<span class="confirm-bb" :title="item.ProductName">{{ item.ProductName }}</span>
								<!-- <span class="confirm-be">(安心、浪漫樱花、泰行天下、邮轮产品)</span> -->
								<span class="confirm-bc">{{ item.EffectiveStart }} 至 {{ item.EffectiveEnd }}</span>
								<span class="confirm-bd">有效期：{{ item.Days }}天</span>
							</div>
							<div class="confirm-zx">
								<a href="javascript:;" @click="insu" class="confirm-ac">
									<i class="">重新选择</i>
								</a>
							</div>
						</div>

						<div class="confirm-ca">
							<div class="confirm-cc">
								<div class="confirm-cca">
									<div class="confirm-ccb">
										<div class="confirm-cco">价格（成人）：</div>
										<div class="confirm-ccc">
											<!-- <div class="confirm-ccc confirm-cce confirm-ccf">
												<div class="confirm-cch">¥</div>
												<div class="">{{ priceInfo.AdultPrice }}</div>
											</div> -->
											<div class="confirm-ccc confirm-ccf confirm-ccg">
												<div class="confirm-cch">¥</div>
												<div class="">{{ priceInfo.AdultPrice }}</div>
											</div>
											<div class="confirm-ccc ">
												<div class="confirm-cch confirm-cci">X</div>
												<div class="confirm-cci">{{ priceInfo.AdultCount }}</div>
												<div class="">人</div>
											</div>
										</div>
									</div>
									<div class="confirm-ccb">
										<div class="confirm-cco">价格（小孩）：</div>
										<div class="confirm-ccc">
											<!-- <div class="confirm-ccc confirm-cce confirm-ccf">
												<div class="confirm-cch">¥</div>
												<div class="">{{ priceInfo.AdultPrice }}</div>
											</div> -->
											<div class="confirm-ccc confirm-ccf confirm-ccg">
												<div class="confirm-cch">¥</div>
												<div class="">{{ priceInfo.ChildPrice }}</div>
											</div>
											<div class="confirm-ccc ">
												<div class="confirm-cch confirm-cci">X</div>
												<div class="confirm-cci">{{ priceInfo.ChildCount }}</div>
												<div class="">人</div>
											</div>
										</div>
									</div>
									<div class="confirm-ccb">
										<div class="confirm-cck">总金额</div>
										<div class="confirm-ccc confirm-ccg">
											<div class="">¥</div>
											<div class="confirm-ccz">{{ priceInfo.TotalPrice}}</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <span class="confirm-ce">
								同行价总金额：
								<b class="confirm-jq">￥{{ priceInfo.TotalPrice}}</b>
							</span> -->
						</div>
					</block>
				</block>
			</div>
		</div>


		<!--常用旅客-->
		<selectPassenger :showOftenPassenger="showOftenPassenger" :IsChinaCity="IsChinaCity"
			@hideSelectPassengerBox="hideSelectPassengerBox" @bindSelectPassenger="bindSelectPassenger">
		</selectPassenger>
	</div>
</template>

<script>
	import desModal from '../../components/desModal.vue';
	import selectPassenger from '../../components/selectPassenger.vue';
	import personNoData from '../../components/personNoData.vue';
	import Table from '../../components/table.vue';

	import {
		getInsurSearchInfo,
		setInsurSearchInfo,
		getAccountInfo,
		getBookingFormInfo,
		setBookingFormInfo
	} from '@/utils/localdatas';

	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js'); //通用类库
	var datehelper = require('@/utils/datehelper.js');
	var destApi = require('@/api/destApi.js'); //目的地
	var insApi = require('@/api/insApi.js'); //保险接口
	//接口
	var memberApi = require('@/api/memberApi.js');
	import {
		submitOrder
	} from '@/api/order';
	
	var that;

	export default {
		components: {
			// desModal,
			selectPassenger,
			personNoData
		},
		data() {
			return {
				show: false,
				show1: 0,
				show2: 0,
				show3: false,
				show4: false,
				show5: false,
				show6: 0,
				show7: 0,
				sfz: false,
				ximing: true,
				dialogVisible: false,
				showWorkerValue: false,
				checked1: true,
				checked2: true,
				optionst: [{
					value: '选项1',
					label: '中国大陆+86'
				}, {
					value: '选项2',
					label: '中国大陆+0086'
				}],
				value: '',
				progress: [{
						name: '选择保险',
						current: true
					},
					{
						name: '填写资料',
						current: true
					},
					{
						name: '确定订单'
					},
					{
						name: '预订成功'
					}
				],
				multipleSelection: [],
				BookInsuranceInfo: '',
				priceInfo: {
					TotalPrice: 0,
					AdultPrice: 0,
					AdultTax: 0,
					ChildPrice: 0,
					ChildTax: 0,
					RCDPrice: 0,
					AgentADTPrice: 0,
					AgentCHDPrice: 0,
					AgentRCDPrice: 0,
					AgentRTotalPrice: 0,
					AdultCount: 1,
					ChildCount: 0,
					Discount: 1
				},
				form: {
					linkman: '',
					area: '0086',
					phone: '',
					email: '',
					remark: '',
					isseat: 0,
					seat: '靠窗',
					PassengerListData: []
				},
				InsuranceRelationships: [],
				InsuranceRoles: [],
				Countrys: [],
				showOftenPassenger: false,
				IsChinaCity: false
			};
		},
		//创建
		created() {
			that = this;
			this.$router.push({
				path: '/'
			}); 
			var accountInfo = getAccountInfo();
			if (accountInfo != null) {
				that.form.linkman = accountInfo.Name;
				that.form.email = accountInfo.Email;
				that.form.phone = accountInfo.Mobile;
				// that.form.linkman=accountInfo.Name;
			}
			getBookerInsuranceInfo();
			getInsuranceRelationships();
			getCountrys();
			$(window).scrollTop("0");
		},
		//方法
		methods: {
			showWorker() {
				this.showWorkerValue = true;
			},
			showWorkernone() {
				console.log(1);
				this.showWorkerValue = false;
				console.log(this.showWorkerValue);
			},
			insu(e) {
				this.$router.push({
					path: '/insurance'
				});
			},
			fanhui(e) {
				this.$router.push({
					path: '/Insurance/detail'
				});
			},
			xiayibu(e) {
				this.$router.push({
					path: '/Insurance/confirm'
				});
			},

			//新增旅客
			addPassenger(e) {
				var PassengerListData = that.form.PassengerListData;
				if (PassengerListData.length >= 9) {
					return;
				}
				var new_passenger = {
					ID: Date.now(),
					Man_id: '',
					Man_xing: '',
					Man_ming: '',
					Man_xingming: '',
					Man_type_p: 'ADT',
					Man_sex: '1',
					Birthday: '',
					CertificateType: 'PP',
					CertificateDate: '',
					CertificateNo: '',
					Man_SaveOrNo: '0',
					Needinsure: '0',
					NeedVisa: '0',
					Nationality: 'CN',
					Seat: '无',
					ExtWorkNo: '',
					CertificateTypeList: PassengerListData[0].CertificateTypeList
				};
				PassengerListData.push(new_passenger);
				that.form.PassengerListData = PassengerListData;
				setPriceInfo();
			},
			//删除一个旅客
			delPassenger(idx) {
				var PassengerListData = that.form.PassengerListData;
				if (PassengerListData.length <= 1) {
					return;
				}
				PassengerListData.splice(idx, 1);
				that.form.PassengerListData = PassengerListData;
				setPriceInfo();
			},
			//选择常旅客
			bindShowOftenPassenger() {
				console.log(1);
				this.showOftenPassenger = true;
			},
			hideSelectPassengerBox() {
				this.showOftenPassenger = false;
			},
			bindSelectPassenger(selectPassengerList) {
				this.showOftenPassenger = false;
				console.log(selectPassengerList);
				if (selectPassengerList.length == 0 || selectPassengerList == null) {
					return;
				}
				let PassengerList = that.form.PassengerListData;

				// let PassengerList = [];
				console.log(PassengerList);

				for (let idx = 0; idx < selectPassengerList.length; idx++) {
					let Man_xing = '';
					let Man_ming = '';
					let Man_xingming = '';
					let Passenger = selectPassengerList[idx];

					if (that.IsChinaCity && Passenger.FullName != '') {
						Man_xing = Passenger.FirstNameCn;
						Man_ming = Passenger.LastNameCn;
					} else {
						Man_xing = Passenger.LastNameEn;
						Man_ming = Passenger.FirstNameEn;
					}
					Man_xingming=Man_xing+Man_ming;
					var new_Passenger = {};
					new_Passenger.Man_id = Passenger.PersonCode;
					new_Passenger.Man_type_p = Passenger.PassengerAgeSection;
					new_Passenger.Man_type_p_name = Passenger.PassengerAgeSection == 'CHD' ? '小孩' : '成人';
					new_Passenger.Man_xing = Man_xing;
					new_Passenger.Man_ming = Man_ming;
					new_Passenger.Man_xingming = Man_xingming;
					new_Passenger.Birthday = Passenger.BirthDay;
					new_Passenger.Nationality = Passenger.Nationality;
					new_Passenger.Man_sex = Passenger.Sex;
					let cre_index = 0;

					if (that.IsChinaCity) {
						for (var i = 0; i < Passenger.WXCredentials.length; i++) {
							if (Passenger.WXCredentials[i].ProofType == 'ID') {
								cre_index = i;
							}
						}
					} else {
						for (var i = 0; i < Passenger.WXCredentials.length; i++) {
							if (Passenger.WXCredentials[i].ProofType == 'PP') {
								cre_index = i;
							}
						}
					}

					new_Passenger.CertificateDate = Passenger.WXCredentials[cre_index].EndDate;
					new_Passenger.CertificateNo = Passenger.WXCredentials[cre_index].Number;
					new_Passenger.CertificateTypeIndex = Passenger.WXCredentials[cre_index].CertificateTypeIndex;
					new_Passenger.CertificateType = Passenger.WXCredentials[cre_index].ProofType;
					new_Passenger.CertificateTypeName = Passenger.WXCredentials[cre_index].ProofName;

					new_Passenger.CertificateTypeList = PassengerList[0].CertificateTypeList;
					console.log(new_Passenger);
					// PassengerList[idx] = new_Passenger; //
					PassengerList.push(new_Passenger);
				}

				console.log(PassengerList);
				that.form.PassengerListData = PassengerList;
				setPriceInfo();
			},
			
			//提交订单
			bindSubmit: function(e) {
				var values = that.form;
				console.log(that.form);
				// return;
				let PassengerList = values.PassengerListData;
				let touBaoCount = 0;
				let AdtCount = 0;
				for (var i = 0; i < PassengerList.length; i++) {
					let InsuranceRole = PassengerList[i].InsuranceRole;
					let CertificateType = PassengerList[i].CertificateType;
					let CertificateNo = PassengerList[i].CertificateNo;
					let Man_xing = PassengerList[i].Man_xing;
					let Man_ming = PassengerList[i].Man_ming;
					let Man_xingming = PassengerList[i].Man_xingming;
					let Birthday = PassengerList[i].Birthday;
					let tips = '请填写旅客' + (i + 1);
					if (util.isNullOrEmpty(Man_xingming)) {
						that.$alert(tips + '的姓名');
						return;
					}
					// if (util.isNullOrEmpty(Man_xing)) {
					// 	that.$alert(tips + '的姓');
					// 	return;
					// }
					// if (util.isNullOrEmpty(Man_ming)) {
					// 	that.$alert(tips + '的名');
					// 	return;
					// }
					if (util.isNullOrEmpty(Birthday)) {
						that.$alert(tips + '的生日');
						return;
					}
					if (util.isNullOrEmpty(PassengerList[i].CertificateNo)) {
						that.$alert(tips + '的证件号码');
						return;
					}
					PassengerList[i].Man_xingming = Man_xing + '/' + Man_ming;
					PassengerList[i].Needinsure = "1";
					if (CertificateType == '身份证' || CertificateType == 'ID') {
						let cardInfo = yqfCommon.getIDCardInfo(CertificateNo);
						console.log(cardInfo);
						if (cardInfo != null) {
							Birthday = cardInfo.birthday;
							PassengerList[i].Birthday = Birthday;
							PassengerList[i].Man_sex = cardInfo.sex == '男' ? '1' : '0';
							if (cardInfo.age < 18) {
								PassengerList[i].Man_type_p = 'CHD'
							} else {
								PassengerList[i].Man_type_p = 'ADT'
								AdtCount++;
							}
						}
					}
					if (!util.isNullOrEmpty(Birthday)) {
						let age = yqfCommon.getAgeByBirthday(Birthday);
						console.log(age);
						if (age < 18) {
							PassengerList[i].Man_type_p = 'CHD';
						} else {
							PassengerList[i].Man_type_p = 'ADT';
							AdtCount++;
						}
					}
				
					let Nationality = PassengerList[i].Nationality;
				
					if (Nationality instanceof Array) {
						PassengerList[i].Nationality = Nationality[0];
					}
					// if(Nationality.length>0){
					// 	PassengerList[i].Nationality=Nationality[0];
					// }
					if (PassengerList[i].Man_type_p == 'ADT') {
						if (InsuranceRole == 1 || InsuranceRole == 2) {
							touBaoCount++;
						}
					}
				}
				console.log(AdtCount);
				if (AdtCount == 0) {
					that.$alert('请选择一个成人的投保人');
					return;
				}
				if (touBaoCount == 0) {
					that.$alert('请选择一个投保人');
					return;
				}
				if (touBaoCount > 1) {
					that.$alert('只能有一个投保人');
					return;
				}
				
				let selectInsListJson = JSON.stringify(that.BookInsuranceInfo);
			
				let PassengerListJson = JSON.stringify(PassengerList);
			
				let accountinfoJson = '';
				if (util.checkLogin()) {
					accountinfoJson = JSON.stringify(getAccountInfo());
				}
				let parm = {					
					selectInsList: selectInsListJson,
					PassengerList: PassengerListJson,
					// InsurancelList: InsurancelList,
					// selectCoupon: selectCoupon,
					accountinfo: accountinfoJson,
			
					// isneedinsure: values.isneedinsure,
					SendLinkMan: values.linkman,
					SendLinkMobile: values.phone,
					SendLinkEmail: values.email,
					SendLinkRemark: values.remark,
			
					IsChinaCity: true,
					// isbingPhone: that.isbingPhone,
					// VCode: values.VCode,
					// payTypeID: that.payTypeID,
					ordertype: 'ins',
					// isus: 'true'
				};
				console.log(parm);
				// return;
			
				const loading = this.$loading({
					lock: true,
					text: '订单提交中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			
				submitOrder(parm, function(result) {
					loading.close();
					if (result.code == 0) {
						that.$router.push({
							path: '/Order/orderResult',
							query: {
								id: result.data.LR_BillNO
							}
						});	
					} else if (result.code == -1) {
						that.$confirm(result.msg, '提示', {
							distinguishCancelAndClose: true,
							confirmButtonText: '确定'
						}).then(() => {
							that.$router.push({
								path: '/'
							});
						});
					} else {
						that.$alert(result.msg);
					}
				});
			},
			bindNext(e) {
				console.log(that.form);
				// return;
				let PassengerList = that.form.PassengerListData;
				let touBaoCount = 0;
				let AdtCount = 0;
				for (var i = 0; i < PassengerList.length; i++) {
					let InsuranceRole = PassengerList[i].InsuranceRole;
					let CertificateType = PassengerList[i].CertificateType;
					let CertificateNo = PassengerList[i].CertificateNo;
					let Man_xing = PassengerList[i].Man_xing;
					let Man_ming = PassengerList[i].Man_ming;
					let Man_xingming = PassengerList[i].Man_xingming;
					let Birthday = PassengerList[i].Birthday;
					let tips = '请填写旅客' + (i + 1);
					if (util.isNullOrEmpty(Man_xingming)) {
						that.$alert(tips + '的姓名');
						return;
					}
					// if (util.isNullOrEmpty(Man_xing)) {
					// 	that.$alert(tips + '的姓');
					// 	return;
					// }
					// if (util.isNullOrEmpty(Man_ming)) {
					// 	that.$alert(tips + '的名');
					// 	return;
					// }
					if (util.isNullOrEmpty(Birthday)) {
						that.$alert(tips + '的生日');
						return;
					}
					if (util.isNullOrEmpty(PassengerList[i].CertificateNo)) {
						that.$alert(tips + '的证件号码');
						return;
					}
					PassengerList[i].Man_xingming = Man_xing + '/' + Man_ming;
					PassengerList[i].Needinsure = "1";
					if (CertificateType == '身份证' || CertificateType == 'ID') {
						let cardInfo = yqfCommon.getIDCardInfo(CertificateNo);
						console.log(cardInfo);
						if (cardInfo != null) {
							Birthday = cardInfo.birthday;
							PassengerList[i].Birthday = Birthday;
							PassengerList[i].Man_sex = cardInfo.sex == '男' ? '1' : '0';
							if (cardInfo.age < 18) {
								PassengerList[i].Man_type_p = 'CHD'
							} else {
								PassengerList[i].Man_type_p = 'ADT'
								AdtCount++;
							}
						}
					}
					if (!util.isNullOrEmpty(Birthday)) {
						let age = yqfCommon.getAgeByBirthday(Birthday);
						console.log(age);
						if (age < 18) {
							PassengerList[i].Man_type_p = 'CHD';
						} else {
							PassengerList[i].Man_type_p = 'ADT';
							AdtCount++;
						}
					}

					let Nationality = PassengerList[i].Nationality;

					if (Nationality instanceof Array) {
						PassengerList[i].Nationality = Nationality[0];
					}
					// if(Nationality.length>0){
					// 	PassengerList[i].Nationality=Nationality[0];
					// }
					if (PassengerList[i].Man_type_p == 'ADT') {
						if (InsuranceRole == 1 || InsuranceRole == 2) {
							touBaoCount++;
						}
					}
				}
				console.log(AdtCount);
				if (AdtCount == 0) {
					that.$alert('请选择一个成人的投保人');
					return;
				}
				if (touBaoCount == 0) {
					that.$alert('请选择一个投保人');
					return;
				}
				if (touBaoCount > 1) {
					that.$alert('只能有一个投保人');
					return;
				}
				that.form.PassengerListData = PassengerList;
				setBookingFormInfo(that.form);

				that.$router.push({
					path: '/Insurance/confirm'
				});
			}
		}
	};
	//预定信息
	function getBookerInsuranceInfo() {
		var parm = {};
		insApi.GetBookerInsuranceInfo(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				let BookInsuranceInfo = result.data.BookInsuranceInfo;
				let priceInfo = that.priceInfo;

				that.BookInsuranceInfo = BookInsuranceInfo;

				priceInfo.AdultCount = 1;
				priceInfo.AdultPrice = BookInsuranceInfo[0].ADTPrice;
				priceInfo.ChildPrice = BookInsuranceInfo[0].CHDPrice;
				priceInfo.RCDPrice = BookInsuranceInfo[0].RCDPrice;
				priceInfo.AgentADTPrice = BookInsuranceInfo[0].AgentADTPrice;
				priceInfo.AgentCHDPrice = BookInsuranceInfo[0].AgentCHDPrice;
				priceInfo.AgentRCDPrice = BookInsuranceInfo[0].AgentRCDPrice;
				priceInfo.Discount = result.data.agentDiscount;
				that.priceInfo = priceInfo;
				getPassengerList();
			} else {
				that.$alert(result.msg, {
					confirmButtonText: '确定',
					callback: action => {
						that.$router.push({
							path: '/Insurance/list'
						});
					}
				});
			}
		});
	}

	//确认选择的旅客
	function setSelectPassengerList(selectPassengerList) {
		let PassengerList = [];
		console.log(PassengerList);

		for (let idx = 0; idx < selectPassengerList.length; idx++) {
			let Man_xing = '',
				Man_ming = '';
			let Passenger = selectPassengerList[idx];

			if (that.IsChinaCity && Passenger.FullName != '') {
				Man_xing = Passenger.FirstNameCn;
				Man_ming = Passenger.LastNameCn;
			} else {
				Man_xing = Passenger.LastNameEn;
				Man_ming = Passenger.FirstNameEn;
			}

			var new_Passenger = {};
			new_Passenger.Man_id = Passenger.PersonCode;
			new_Passenger.Man_type_p = Passenger.PassengerAgeSection;
			new_Passenger.Man_type_p_name = Passenger.PassengerAgeSection == 'CHD' ? '小孩' : '成人';
			new_Passenger.Man_xing = Man_xing;
			new_Passenger.Man_ming = Man_ming;
			new_Passenger.Birthday = Passenger.BirthDay;
			new_Passenger.Nationality = Passenger.Nationality;
			new_Passenger.Man_sex = Passenger.Sex;
			let cre_index = 0;

			if (that.IsChinaCity) {
				for (var i = 0; i < Passenger.WXCredentials.length; i++) {
					if (Passenger.WXCredentials[i].ProofType == 'ID') {
						cre_index = i;
					}
				}
			} else {
				for (var i = 0; i < Passenger.WXCredentials.length; i++) {
					if (Passenger.WXCredentials[i].ProofType == 'PP') {
						cre_index = i;
					}
				}
			}

			new_Passenger.CertificateDate = Passenger.WXCredentials[cre_index].EndDate;
			new_Passenger.CertificateNo = Passenger.WXCredentials[cre_index].Number;
			new_Passenger.CertificateTypeIndex = Passenger.WXCredentials[cre_index].CertificateTypeIndex;
			new_Passenger.CertificateType = Passenger.WXCredentials[cre_index].ProofType;
			new_Passenger.CertificateTypeName = Passenger.WXCredentials[cre_index].ProofName;
			console.log(new_Passenger);
			PassengerList[idx] = new_Passenger; //PassengerList.push(arrParse);
		}

		console.log(PassengerList);
		that.form.PassengerListData = PassengerList;

		setPriceInfo();
		// savePassengerList();
	}
	//旅客信息
	function getPassengerList() {
		let form = getBookingFormInfo();
		console.log(form);
		if (util.isNullOrEmpty(form)) {
			memberApi.GetPassengerList({}, function(result) {
				console.log(result);
				if (result.code == 0) {
					let PassengerListData = JSON.parse(result.data.PassengerList);
					console.log(PassengerListData);
					that.form.PassengerListData = PassengerListData;
					setPriceInfo();
				}
			});
		} else {
			that.form = form;
			setPriceInfo();
		}
	}

	function setPriceInfo() {
		let priceInfo = that.priceInfo;
		console.log(priceInfo);
		let PassengerList = that.form.PassengerListData;
		let adultCount = 0;
		let childCount = 0;
		let TotalPrice = priceInfo.TotalPrice;

		let AdultTotalPrice = priceInfo.AdultPrice;
		let ChildTotalPrice = priceInfo.ChildPrice;
		let RCDTotalPrice = priceInfo.RCDPrice;

		let AgentADTPrice = priceInfo.AgentADTPrice;
		let AgentCHDPrice = priceInfo.AgentCHDPrice;
		let AgentRCDPrice = priceInfo.AgentRCDPrice;

		for (var i = 0; i < PassengerList.length; i++) {
			if (PassengerList[i].Man_type_p == 'CHD') {
				childCount++;
			} else {
				adultCount++;
			}
		}

		AdultTotalPrice = AdultTotalPrice * adultCount;
		ChildTotalPrice = ChildTotalPrice * childCount;
		RCDTotalPrice = RCDTotalPrice * childCount;

		TotalPrice = AdultTotalPrice + ChildTotalPrice + RCDTotalPrice;

		priceInfo.AdultCount = adultCount;
		priceInfo.ChildCount = childCount;
		priceInfo.TotalPrice = TotalPrice;
		that.priceInfo = priceInfo;
	}
	//保险关系列表
	function getInsuranceRelationships() {
		insApi.GetInsuranceRelationships({}, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.InsuranceRoles = result.data.InsuranceRoles;
				that.InsuranceRelationships = result.data.InsuranceRelationships;
			}
		});
	}
	//
	function getCountrys() {
		destApi.GetCountrys({}, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.Countrys = result.data.Countrys;
			}
		});
	}
</script>

<style lang="scss">
	@import '../../style/Insurance/confirm.scss';
</style>
